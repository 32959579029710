import React from "react";
import { graphql } from "gatsby";
import { AppStore, Redoc } from "redoc";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import "../../node_modules/@fortawesome/fontawesome-free/css/brands.min.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";

export default ({ data }) => {
  const { swaggerApi } = data
  const [isLoading, setIsLoading] = React.useState(true)
  const [store] = React.useState(() => {
    const spec = JSON.parse(swaggerApi.spec)
    // Do not use actual swagger url here
    const _store = new AppStore(spec, /*, swaggerApi.url */ null, {
      noAutoAuth: true,
    })

    const theme = _store.options.theme
    theme.typography.headings.fontFamily = "Roboto, sans-serif"
    theme.typography.code.fontFamily = '"Roboto Mono", monospace'
    
    // $color-theme-primary-dark
    theme.colors.primary.main = "#b3c559"
    // $color-content-text
    theme.colors.text.primary = "#6a6f85"
    // $color-theme-menu-text
    theme.sidebar.textColor = "#000000"

    theme.rightPanel.backgroundColor = "#23232C"

    return _store
  })

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <Layout loading={isLoading}>
      <SEO title={swaggerApi.title} />
      {isLoading && <div className="loader">Loading</div>}
      <Redoc store={store} />
    </Layout>
  )
}

export const query = graphql`
  query($name: String!) {
    swaggerApi(name: { eq: $name }) {
      name
      spec
      title
      url
    }
  }
`
